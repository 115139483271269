import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) =>
    ({
        back: {
            backgroundColor: theme.colors.secondaryVar1,
            height: 100,
            textAlign: "left",
            display: "grid",
            alignItems: "center",
            paddingLeft: 30,
            fontFamily: theme.font.family,
            '&:hover': {
                cursor: 'pointer',
            }
        },
        textBox: {
            maxWidth: 600,
            margin: "auto",
            textAlign: "left",
            padding: "0 20px",
            backgroundOrigin: "padding-box",
        },
        title: {
            margin: "35px 0",
            fontFamily: theme.font.family,
            color: theme.colors.mainText,
            fontSize: theme.font.subtitleSize,
            fontWeight: theme.font.strongWeight,
        },
        description: {
            fontFamily: theme.font.family,
            color: theme.colors.mainText,
            fontSize: theme.font.size,
            whiteSpace: "pre-wrap",
            lineHeight: "33px",
        },
        requirementsTitle: {
            fontFamily: theme.font.family,
            color: theme.colors.mainText,
            fontSize: theme.font.largeSize,
            fontWeight: theme.font.strongWeight,
            marginTop: 60,
        },
        subHeader: {
            fontFamily: theme.font.family,
            color: theme.colors.mainText,
            fontSize: theme.font.size,
            fontWeight: theme.font.strongWeight,
            marginTop: 60,
        },
        requirements: {
            whiteSpace: "pre-wrap",
            lineHeight: "33px",
        },
        apply: {
            display: "inline-block",
            margin: "20px auto",
            padding: 10,
            borderRadius: 4,
            color: theme.colors.var1,
            fontFamily: theme.font.family,
            lineHeight: "28px",
            fontSize: theme.font.size,
            backgroundColor: theme.colors.brightText,
            textDecoration: "none",
            border: '2px solid #804669',
        },
        bold:{
            fontWeight: 600,
        }
    }));

export default useStyles;
