import React from 'react';
import {setPageDetails} from "../../store/views.actions";
import {PagesEnum} from "../../definitions/pages-enum";
import {useDispatch} from "react-redux";
import positions from "../../assets/HR/positions.json";
import {useHistory, useParams} from 'react-router-dom';
import useStyles from "./JobDescriptionPage.css";
import {PositionInterface} from "../../definitions/position.interface";

function JobDescriptionPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {positionId} = useParams();
    const classes = useStyles();

    const position: PositionInterface | undefined = positions.find(obj => {
        return obj.id === positionId
    });

    //if job description was removed or an illegal sub path was entered
    //will reroute to career page
    if(!position){
        history.push(`/${PagesEnum.CAREER}`);
        return(<div/>);
    }

    const pageDetails ={
        pageTitle:`Careers - ${position.title}`,
        coverImage:null,
        coverTitle:null,
        coverSubTitle:null
    };
    dispatch(setPageDetails(pageDetails));
    // const applyLink = position.applyLink || `mailto:hr@webiks.com?subject=Apply%20for%20${encodeURI(position.title)}%20position`;
    return (
        <div className={'Page-JobDescriptionPage'}>

            <div className={classes.back}
                 onClick={() => {
                     history.push(`/${PagesEnum.CAREER}`);
                 }}>
                {'<< Open positions'}
            </div>
            <div className={classes.textBox}>
                <h1 className={classes.title}>{position.title}</h1>
                {
                    position.descriptions && position.descriptions.map((item,key)=><span key={key}>
                        {item.title && <h2 className={classes.subHeader}>{item.title}</h2>}
                        {
                            !!item.list?
                                <ul className={classes.requirements}>
                                    {
                                        item.list.map((descriptionItem,key2)=>
                                            <li key={key2}>{descriptionItem}</li>
                                        )
                                    }
                                </ul>
                            :
                                <p className={classes.description}>{item.text}</p>
                        }
                    </span>
                    )
                }
                <h2 className={classes.requirementsTitle}>Requirements:</h2>
                {
                    position.requirements.map((item,key)=><span key={key}>
                        {item.title && <h2 className={classes.subHeader}>{item.title}</h2>}
                        {item.text && <p className={classes.description}>{item.text}</p>}
                        {
                            !!item.list &&
                            <ul className={classes.requirements}>
                                {
                                    item.list.map((value, key) => {
                                        return (
                                            <li key={key}>{value}</li>
                                        )
                                    })
                                }
                            </ul>
                        }
                    </span>)
                }
                {
                    position.extra && position.extra.map((item, key) => <span key={key}>
                        {item.title && <h2 className={classes.subHeader}>{item.title}</h2>}
                        {item.text && <p className={classes.description}>{item.text}</p>}
                        {
                            !!item.list &&
                            <ul className={classes.requirements}>
                                {
                                    item.list.map((value, key) => {
                                        return (
                                            <li key={key}>{value}</li>
                                        )
                                    })
                                }
                            </ul>
                        }
                    </span>)
                }

                <div className={classes.apply}>
                    To Apply For This Position Please Email Your CV To
                    &nbsp;
                    <span className={classes.bold}>
                        HR@WEBIKS.COM
                    </span>
                </div>
            </div>
        </div>
    );
}

export default JobDescriptionPage;
